@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Neue Montreal";
  src: url("./assets/fonts/PPNeueMontreal-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("./assets/fonts/PPNeueMontreal-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("./assets/fonts/PPNeueMontreal-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("./assets/fonts/PPNeueMontreal-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("./assets/fonts/PPNeueMontreal-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("./assets/fonts/PPNeueMontreal-SemiBolditalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@layer components {
  .header-text {
    font-family: 'Neue Montreal', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
}

body {
  margin: 0;
  font-family: "Neue Montreal", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}